import {IGallerySantaProps} from '../types/galleryTypes';
import {IPropsInjectedByViewerScript} from '../types/sliderGalleryTypes';
import {IHostProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IStylesContextValue} from '@wix/tpa-settings';
import {IStylesParams} from '../baseStylesParams';

type ProvidedGlobalPropNames =
  | 'allowFreeProducts'
  | 'addedToCartStatus'
  | 'experiments'
  | 'getCategoryProducts'
  | 'handleAddToCart'
  | 'handleProductItemClick'
  | 'handleProductsOptionsChange'
  | 'sendClickShippingInfoLinkSf'
  | 'htmlTags'
  | 'imageMode'
  | 'imageRatio'
  | 'isFirstPage'
  | 'isInteractive'
  | 'isLiveSiteMode'
  | 'isPreviewMode'
  | 'shouldShowMobile'
  | 'isCategoryVisible'
  | 'isEditorMode'
  | 'isRTL'
  | 'isSEO'
  | 'isSSR'
  | 'mainCollectionId'
  | 'openQuickView'
  | 'productsManifest'
  | 'productsVariantInfoMap'
  | 'products'
  | 'priceBreakdown'
  | 'shouldShowAddToCartSuccessAnimation'
  | 'shouldShowAddToCartButton'
  | 'shouldShowAutomaticDiscountDataOnGallery'
  | 'shouldShowQuantity'
  | 'shouldShowProductOptions'
  | 'showTitle'
  | 'textsMap'
  | 'totalProducts'
  | 'updateAddToCartStatus'
  | 'productsPriceRangeMap';

export interface ISliderGlobalProps {
  globals?: Pick<IPropsInjectedByViewerScript & IGallerySantaProps, ProvidedGlobalPropNames> & {
    styles: IStylesContextValue;
    stylesParams: IStylesParams;
  } & Pick<IHostProps, 'updateLayout' | 'dimensions' | 'appLoadBI'>;
}

export const sliderGlobalPropsStrategy = (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
  const {
    allowFreeProducts,
    addedToCartStatus,
    experiments,
    getCategoryProducts,
    handleAddToCart,
    handleProductItemClick,
    handleProductsOptionsChange,
    htmlTags,
    sendClickShippingInfoLinkSf,
    imageMode,
    imageRatio,
    isFirstPage,
    isInteractive,
    isLiveSiteMode,
    isPreviewMode,
    shouldShowMobile,
    isCategoryVisible,
    isEditorMode,
    isRTL,
    isSEO,
    isSSR,
    mainCollectionId,
    openQuickView,
    priceBreakdown,
    productsManifest,
    productsVariantInfoMap,
    products,
    shouldShowAddToCartSuccessAnimation,
    shouldShowAddToCartButton,
    shouldShowAutomaticDiscountDataOnGallery,
    shouldShowQuantity,
    shouldShowProductOptions,
    showTitle,
    textsMap,
    totalProducts,
    updateAddToCartStatus,
    productsPriceRangeMap,
    ...locals
  } = props;

  const {appLoadBI, dimensions, updateLayout} = props.host;
  return {
    globals: {
      allowFreeProducts,
      addedToCartStatus,
      appLoadBI,
      dimensions,
      experiments,
      getCategoryProducts,
      handleAddToCart,
      handleProductItemClick,
      handleProductsOptionsChange,
      htmlTags,
      imageMode,
      imageRatio,
      isFirstPage,
      isInteractive,
      sendClickShippingInfoLinkSf,
      isLiveSiteMode,
      isPreviewMode,
      shouldShowMobile,
      isCategoryVisible,
      isEditorMode,
      isRTL,
      isSEO,
      isSSR,
      mainCollectionId,
      openQuickView,
      priceBreakdown,
      productsManifest,
      productsVariantInfoMap,
      products,
      shouldShowAddToCartButton,
      shouldShowAutomaticDiscountDataOnGallery,
      shouldShowQuantity,
      shouldShowProductOptions,
      showTitle,
      shouldShowAddToCartSuccessAnimation,
      textsMap,
      totalProducts,
      updateAddToCartStatus,
      updateLayout,
      productsPriceRangeMap,
    },
    locals,
  };
};
